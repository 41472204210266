/* yellow font color #EFE8766 */

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0c0c0c !important;
  opacity: 0.9;
  color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Universal Styles */
.sanity-img {
  max-width: 100%;
}
.link {
  color: #ced0d5;
  font-weight: bold;
  margin: 10px 25px;
  text-decoration: none;
}

.about-title,
h2 {
  color: #efe766;
}

h3,
h4 {
  margin: 2rem;
}

@media screen and (max-width: 768px) {
  .photos-title,
  .title {
    font-size: 3rem !important;
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 768px) {
  h3 {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  h4 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 768px) {
  h5 {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 768px) {
  p {
    font-size: 12px !important;
  }
}

/* Navbar Styles */
Nav {
  background-color: #0c0c0c;
}
.nav-logo {
  max-width: 200px;
  overflow: hidden;
}
.nav-link {
  margin-right: 1rem;
}
.nav-item {
  color: white;
  text-align: center;
}
.nav-item:hover {
  color: darkgray;
}

/* Home Styles */
.landing {
  height: 100vh;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .landing {
    height: 90vh;
    width: 100%;
  }
}
.title {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 4.5rem;
  text-shadow: 1px 1px 2px black, 0 0 1em #222222, 0 0 0.2em #222222;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* About Styles */
.about-col {
  text-align: center;
}
/* Photos Styles */
.photos-title {
  color: white;
  font-weight: bold;
  font-size: 4rem;
}
.gallery {
  display: flex;
  align-items: center;
  height: 85vh;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .gallery {
    height: 75vh;
  }
}
.arrow-down {
  display: flex;
  color: white;
}
.arrow-down:hover {
  color: #ced0d5;
}

/* Videos Styles */

/* Contact Form Styles */
.contact-form {
  max-width: 75%;
  margin: 0 auto;
  border-color: black;
  border: 1px solid;
  padding: 15px;
  border-radius: 5px;
  background-color: #fefbf6;
  margin-bottom: 50px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .contact-form {
    max-width: 90%;
  }
}
.contact-form label {
  line-height: 2.2rem;
  margin-right: 1rem;
  color: black;
}
.contact-form textarea {
  min-height: 100px;
  width: 100%;
  font-size: 14px;
}
input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
  width: 60%;
}
.contact-info {
  color: black;
}
#submit-btn {
  background-color: black;
  color: white;
  width: 100%;
}
#submit-btn:hover {
  background-color: #1f1f1f;
}

/* Footer Styles */
.footer {
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 1.5rem;
}
.footer-link:hover {
  color: whitesmoke;
}
.copyright {
  font-size: 1rem;
  padding: 20px;
}
